body{
  color: black;
}
#lol{
  background-color: white;
}
td,th
{
  color: black;
}
th{
text-transform: uppercase;
}